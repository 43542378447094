<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>

    <v-dialog v-model="dialog_select_print" width="500">
      <v-card elevation="1">
        <v-card-title class="pr-1">
          ปริ้น
          <v-spacer/>
          <v-btn icon @click="dialog_select_print=false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pl-7 pb-0">
          <div class="py-3">
            <v-radio-group
              v-model="select_print_type"
              row
            >
              <v-radio
                label="ใบแจ้งหนี้"
                :value="1"
              ></v-radio>
              <v-radio
                label="ใบเสร็จรับเงิน / ใบกำกับภาษี"
                :value="2"
              ></v-radio>
            </v-radio-group>
          </div>
          <div>
            <v-menu
              ref="menuSelectInvoiceDate"
              v-model="menuSelectInvoiceDate"
              :close-on-content-click="false"
              :return-value.sync="selectInvoiceDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  outlined
                  :value="selectInvoiceDate"
                  @change="value => selectInvoiceDate = value"
                  autocomplete="off"
                  label="วันที่ออกใบแจ้งหนี้"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  hide-details
                  style="max-width: 300px"

                ></v-text-field>
              </template>
              <v-date-picker v-model="selectInvoiceDate" no-title scrollable :allowed-dates="allowedDates">
              </v-date-picker>
            </v-menu>
          </div>
          <div class="pt-3 pb-5">
            <v-btn outlined color="info" @click="PrintPreview(item_for_print)">
              ตกลง
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmCreateInvoiceDialog" width="500">
      <v-card elevation="1">
        <v-card-title class="pr-1">
          ยืนยันการสร้างใบแจ้งหนี้
          <v-spacer/>
          <v-btn icon @click="confirmCreateInvoiceDialog=false"><v-icon class="fn-25">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pl-7 pb-0">
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ชื่อร้าน</strong> :
            </v-col>
            <v-col cols="6" align="right">
              <span v-if="shop.selected_to_make_invoice.length > 0">{{shop.selected_to_make_invoice[0]['Name']}}</span>
            </v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ค่าส่ง</strong> :
            </v-col>
            <v-col cols="6" align="right">{{formatMoney(dataConfirmCreateInvoice.sendPriceMakeInvoice)}}</v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ค่าพื้นที่ห่างไกล</strong> :
            </v-col>
            <v-col cols="6" align="right">{{formatMoney(dataConfirmCreateInvoice.remotePriceMakeInvoice)}}</v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ค่าCOD</strong> :
            </v-col>
            <v-col cols="6" align="right">{{formatMoney(dataConfirmCreateInvoice.codPriceMakeInvoice)}}</v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ค่ากล่อง</strong> :
            </v-col>
            <v-col cols="6" align="right">{{formatMoney(dataConfirmCreateInvoice.boxPriceMakeInvoice)}}</v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ค่าแพ็ค</strong> :
            </v-col>
            <v-col cols="6" align="right">{{formatMoney(dataConfirmCreateInvoice.packPriceMakeInvoice)}}</v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ส่วนลด</strong> :
            </v-col>
            <v-col cols="6" align="right">{{formatMoney(dataConfirmCreateInvoice.discountMakeInvoice)}}</v-col>
          </v-row>
          <v-row class="py-1">
            <v-col cols="6">
              <strong>ราคารวม</strong> :
            </v-col>
            <v-col cols="6" align="right" style="color: red;">{{formatMoney(Number(parseFloat(dataConfirmCreateInvoice.sendPriceMakeInvoice)+parseFloat(dataConfirmCreateInvoice.remotePriceMakeInvoice)+parseFloat(dataConfirmCreateInvoice.codPriceMakeInvoice)+parseFloat(dataConfirmCreateInvoice.boxPriceMakeInvoice)+parseFloat(dataConfirmCreateInvoice.packPriceMakeInvoice)).toFixed(2))}}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mx-2">
          <v-spacer/>
          <v-btn dark color="primary" class="my-1" v-show="shop.selected_to_make_invoice.length>0" @click="createInvoice()"><v-icon class="ma-1">mdi-content-save</v-icon>สร้างใบแจ้งหนี้</v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading :loading="loading" />

    <v-card elevation="0" class="v-card-margin-bottom-30">
      <v-card-title class="body-1" v-if="navigete!=null && navigete!='' && navigete!='null'">
        การเงิน <v-icon>mdi-chevron-right</v-icon>
        บิลทั้งหมด
      </v-card-title>
    </v-card>


    <v-card class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon size="20" color="primary">fa-sliders-h</v-icon><span class="my-3 ml-3">ค้นหารายการ</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <div>
          <v-row>
            <v-col cols="12" md="2" lg="1" class=" pb-2">
              <span><b> เลือกวันที่ : </b></span>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="  pb-2 ">
              <v-menu
                ref="menuStartDate"
                v-model="menuStartDate"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    :value="startDate"
                    @change="value => startDate = value"
                    autocomplete="off"
                    label="วันที่เริ่มต้น"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    hide-details

                  ></v-text-field>
                </template>
                <v-date-picker v-model="startDate" no-title scrollable :allowed-dates="allowedDates">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12"   md="3" lg="2" class="  pb-2 ">
              <v-menu
                ref="menuEndDate"
                v-model="menuEndDate"
                :close-on-content-click="false"
                :return-value.sync="endDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    :value="endDate"
                    @change="value => endDate = value"
                    autocomplete="off"
                    label="วันที่สิ้นสุด"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-on="on"
                    hide-details

                  ></v-text-field>
                </template>
                <v-date-picker v-model="endDate" no-title scrollable :allowed-dates="allowedDates">
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-2 ">
              <span><b> เลือกร้านค้า : </b></span>
            </v-col>
            <v-col cols="12" md="6" lg="4"  class="pb-2" style="display: flex;">
              <v-autocomplete
                v-model="search_shops"
                :items="shop.list"
                :item-text="item => item.name"
                :item-value="item => item.id "
                outlined
                dense
                hide-details
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-2 ">
              <span><b> InvoiceNo : </b></span>
            </v-col>
            <v-col cols="12" md="6" lg="4"  class="pb-2" style="display: flex;">

              <v-text-field
                v-model="search_InvoiceNo"
                outlined
                placeholder="INV-xxxxxx-xxx"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" lg="1" class="pb-2 ">
            </v-col>
            <v-col cols="12"  md="6" lg="4"   class="pb-2">
              <v-btn dark color="error" @click="getInvoice()" class="fn-14"><v-icon>mdi-magnify</v-icon>ค้นหา</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title>
          <v-icon size="20" color="success">fa-receipt</v-icon><span class="my-3 ml-3">รายการใบแจ้งหนี้</span>
          <v-spacer/>
          <v-btn v-if="shop.selected_to_make_invoice.length>0" class="ma-1" color="primary" @click="confirmCreateInvoice()">สร้างใบแจ้งหนี้</v-btn>
          <v-btn v-show="invoice.table.length != 0" size="1" color="success" dark class="mr-1"><v-icon >mdi-file-excel</v-icon>
            <vue-excel-xlsx
                :data="invoice.table"
                :columns="excel_invoice_list_header"
                :file-name="'รายงานใบแจ้งหนี้'"
                :sheet-name="'รายงานใบแจ้งหนี้'"
                >
                Export to Excel
            </vue-excel-xlsx>
          </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row class="mt-2 mx-0">
          <v-col cols="12" class=" px-0">
            <!-- PC -->
            <v-data-table
              :headers="invoice.headers"
              :key="invoice.key_table"
              :items="invoice.table"
              :footer-props="shop.footer_props"
              item-key="id"
              class="elevation-0 packhai-border-table"
              :mobile-breakpoint="0"
              >
              <template v-slot:item.invoiceNo="{ item }">
                <a @click="openListOrderInvoice(item), shopDisibled = true, branchDisibled = true">{{ item.invoiceNo }}</a>
              </template>


              <template v-slot:item.createdDatetime="{ item }">
                {{ set_format_date_time(item.createdDatetime) }}
              </template>

              <template v-slot:item.calculatedPrice="{ item }">
                {{ format_price(item.calculatedPrice) }}
              </template>
              <template v-slot:item.vatAmount="{ item }">
                {{ format_price(item.vatAmount) }}
              </template>
              <template v-slot:item.totalPrice="{ item }">
                {{ format_price(item.totalPrice) }}
              </template>
              <template v-slot:item.totalItemPrice="{ item }">
                {{ format_price(item.totalItemPrice) }}
              </template>

              <template v-slot:item.invoiceDate="{ item }">
                <span v-if="item.invoiceDate != null">{{ set_format_date_time(item.invoiceDate).substring(0, 10) }}</span>
              </template>

              <template v-slot:item.vatType="{ item }">
                {{ format_vat_type(item.vatType) }}
              </template>

              <template v-slot:item.CancelInvoice="{ item }">
                <v-btn icon><v-icon size="18" color="red" @click="check_delete(item)">fa-trash-alt</v-icon></v-btn>
              </template>

              <template v-slot:item.Print="{ item }">
                  <!-- <v-btn icon><v-icon>mdi-printer</v-icon></v-btn> -->
                  <v-btn icon @click="dialog_print_open(item)"><v-icon color="success">mdi-printer</v-icon></v-btn>
              </template>
            </v-data-table>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { reportService_dotnet } from '@/website/global'
  import { AlertSuccess, AlertWarning, AlertError } from '@/website/global_alert'
  import { FormatInvoice, FormatOrder, formatMoney, isNumberWNoDot, set_format_date_time, format_price, format_vat_type, get_premission_manage, permission_alert } from '@/website/global_function'
  export default {
    components: {
      Loading
    },
    data: () => ({
      page_loading: true,
      loading: false,

      navigete: [],

      // เปิด เลือกวันที่
      startDate: null,
      menuStartDate: false,
      checkStart: true,

      endDate: null,
      menuEndDate: false,
      checkEnd: true,
      // ปิด เลือกวันที่

      selectInvoiceDate: null,
      menuSelectInvoiceDate: false,
      checkInvoiceDate: true,

      search_shops: null,
      search_InvoiceNo: null,

      confirmCreateInvoiceDialog: false,
      tabSelected: null,
      tabList: ['สร้างใบแจ้งหนี้', 'ใบแจ้งหนี้ทั้งหมด'],
      shop: {
        'headers': [
            { text: 'ชื่อร้าน', value: 'Name', sortable: false},
            { text: 'เวลา/วันที่', value: 'SendToExpressDatetime', sortable: false },
            { text: 'ออเดอร์', value: 'ID', sortable: false },
            { text: 'เลขแทรค', value: 'TrackingNo', sortable: false },

            { text: 'ค่าส่ง', value: 'DeliveryPrice', sortable: false, align: 'right' },
            { text: 'ค่าพื้นที่ห่างไกล', value: 'RemotePrice', sortable: false, align: 'right' },
            { text: 'ค่า COD', value: 'CODCharged', sortable: false, align: 'right' },
            { text: 'ค่ากล่อง', value: 'BoxPrice', sortable: false, align: 'right' },
            { text: 'ค่าแพค', value: 'PackPrice', sortable: false, align: 'right' },
            { text: 'ส่วนลด', value: 'Discount', sortable: false, align: 'right' },
            { text: 'ราคารวม', value: 'TotalPrice', sortable: false, align: 'right' },
          ],
        'table': [],
        'footer_props': {
           'items-per-page-options': [50],
           'items-per-page-text': null,
           'disable-items-per-page': true
        },
        'list': [],
        'shop_select': '',
        'selected_to_make_invoice': [],
        'key_table': 0
      },
      dataConfirmCreateInvoice: {
        'dataList': [],
        'sendPriceMakeInvoice': 0,
        'remotePriceMakeInvoice': 0,
        'codPriceMakeInvoice': 0,
        'boxPriceMakeInvoice': 0,
        'packPriceMakeInvoice': 0,
        'discountMakeInvoice': 0,
      },
      invoice: {
        'datetime_search': [new Date(new Date().setDate(new Date().getDate()-1)).toISOString().substr(0, 10),new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10)],
        'showDatetime': false,
        'headers': [
          { text: 'Invoice No', align: 'center', sortable: false, value: 'invoiceNo', width : "150px"},
          { text: 'วันที่สร้าง', align: 'center', sortable: false, value: 'createdDatetime', width : "150px"},
          { text: 'วันที่ใบแจ้งหนี้', align: 'center', sortable: false, value: 'invoiceDate', width : "150px"},
          { text: 'ร้านค้า', align: 'left', sortable: false, value: 'shopName', width : "200px"},
          // { text: 'ค่าส่ง', align: 'right', sortable: false, value: 'DeliveryPrice'},
          // { text: 'ค่าพื้นที่ห่างไกล', align: 'right', sortable: false, value: 'RemotePrice'},
          // { text: 'ส่วนลด', value: 'Discount', sortable: false, align: 'right' },
          // { text: 'ค่า COD', align: 'right', sortable: false, value: 'CODCharged'},
          // { text: 'VAT ค่าCOD', align: 'right', sortable: false, value: 'CODVatAmount'},
          // { text: 'ค่ากล่อง', align: 'right', sortable: false, value: 'BoxPrice'},
          // { text: 'ค่าแพ็ค', align: 'right', sortable: false, value: 'PackPrice'},
          // { text: 'VAT ค่าบริการ', align: 'right', sortable: false, value: 'ServiceVatAmount'},
          // { text: 'รวม', align: 'right', sortable: false, value: 'total'},
          { text: 'ภาษี', align: 'center', sortable: false, value: 'vatType', width : "150px"},
          { text: 'ยอดรวม', align: 'right', sortable: false, value: 'totalItemPrice', width : "100px"},
          { text: 'ยอดที่คำนวนภาษี', align: 'right', sortable: false, value: 'calculatedPrice', width : "150px"},
          { text: 'ภาษีมูลค่าเพิ่ม', align: 'right', sortable: false, value: 'vatAmount', width : "150px"},
          { text: 'ยอดรวมทั้งหมด', align: 'right', sortable: false, value: 'totalPrice', width : "150px"},
          { text: 'เลขอ้างอิง', align: 'center', sortable: false, value: 'groupName', width : "200px"},
          { text: 'สร้างโดย', align: 'center', sortable: false, value: 'createdByName', width : "200px"},
          { text: 'ยกเลิก', align: 'center', sortable: false, value: 'CancelInvoice', width : "100px"},
          { text: 'ปริ้น', align: 'center', sortable: false, value: 'Print', width : "100px"},
        ],
        'key_table': 0,
        'table': []
      },

      ShopDetailInvoiceText: null,
      listOrderInvoice: {
        'headers': [
            { text: '', align: 'center', value: 'num', sortable: false },
            { text: 'ออเดอร์', align: 'center', value: 'orderID', sortable: false },
            { text: 'เลขแทรค', align: 'center', value: 'trackingNo', sortable: false },
            { text: 'วันที่สร้าง', align: 'center', value: 'createdDatetime', sortable: false },
            { text: 'วันที่แพ็ค', align: 'center', value: 'packedDate', sortable: false },
            { text: 'ขนส่ง', align: 'center', value: 'expressCompanyLogo', sortable: false },
            // { text: 'ขนส่ง', align: 'center', value: 'expressCompanyName', sortable: false },
            { text: 'ค่าส่ง', align: 'right', value: 'deliveryPrice', sortable: false },
            { text: 'ค่าพื้นที่ห่างไกล', align: 'right', value: 'remotePrice', sortable: false },
            { text: 'ค่า COD', align: 'right', value: 'codPrice', sortable: false },
            { text: 'ค่ากล่อง', align: 'right', value: 'boxPrice', sortable: false },
            { text: 'ค่าแพค', align: 'right', value: 'packPrice', sortable: false },
            { text: 'ค่าบับเบิล', align: 'right', value: 'bubblePrice', sortable: false },
            { text: 'ส่วนลด', value: 'discount', sortable: false, align: 'right' },
            { text: 'ค่าเชื่อมต่อ Vrich', value: 'vrichFee', sortable: false, align: 'right' },
            { text: 'ราคารวม', align: 'right', value: 'totalPrice', sortable: false },
          ],
        'key_table': 0,
        'table': [],
        'invoiceId': ''
      },

      ch_InvoiceGroupID: null,
      item_invoice_list: [],
      headers_item: [
          { text: 'รายละเอียด', align: 'left', value: 'description', sortable: false },
          { text: 'จำนวน', align: 'right', value: 'quantity', sortable: false },
          { text: 'ราคาต่อหน่วย', align: 'right', value: 'unitPrice', sortable: false },
          { text: 'รวม', align: 'right', value: 'totalPrice', sortable: false },
        ],
      // paginations
      page: 1,
      page_tmp: null,
      row_per_page: 50,
      pageLength: null,

      row_per_page_select: 50,
      row_per_page_select_tmp: 50,
      row_per_page_list: [
        {text: '25', value: 25},
        {text: '50', value: 50},
        {text: '100', value: 100},
        {text: '200', value: 200},
        {text: '500', value: 500},
        {text: 'ทั้งหมด', value: 10000000},
      ],

      IsDataInvoice: null,
      reGetInvoice: 1,

      // ข้อมูล dialog invoice

      invoice_id_row: null,
      shopDisibled: true,
      branchDisibled: true,
      IsReadonly : false,
      ShopAddressType : null,
      BranchAddressType : null,
      dialog_invoice_invoiceID: null,
      dialog_invoice_ShopID : null,
      dialog_invoice_BranchID : null,

      // checkbox for data table
      checkboxList: [],
      checkboxAll: [],
      selected: [],



      // ออกใบกำกับภาษี shop
      Shop_FullAddress_text: null,
      ShopAddressInvoice: {
        "ID":null,
        "Name": null,
        "Phone": null,
        "IDCardNumber": null,
        "TextAddress": null,
        "Address": null,
        "PostcodeMappingID": null,
        "IsDefault": true,
      },

      ShopAddressInvoice_tmp: {
        "ID":null,
        "Name": null,
        "Phone": null,
        "IDCardNumber": null,
        "TextAddress": null,
        "Address": null,
        "PostcodeMappingID": null,
        "IsDefault": true,
      },

      // ออกใบกำกับภาษี branch
      branch_FullAddress_text: null,
      BranchAddressInvoice: {
        "ID":null,
        "Name": null,
        "Phone": null,
        "IDCardNumber": null,
        "TextAddress": null,
        "Address": null,
        "PostcodeMappingID": null,
        "IsDefault": true,
      },

      BranchAddressInvoice_tmp: {
        "ID":null,
        "Name": null,
        "Phone": null,
        "IDCardNumber": null,
        "TextAddress": null,
        "Address": null,
        "PostcodeMappingID": null,
        "IsDefault": true,
      },

      //export to excel
      excel_invoice_list_header : [
          { label: "Invoice No", field: "invoiceNo", width: 15},
          { label: "วันที่สร้าง", field: "createdDatetime", width: 20 ,dataFormat: set_format_date_time},
          { label: "วันที่ใบแจ้งหนี้", field: "invoiceDate", width: 20 ,dataFormat: set_format_date_time},
          { label: "ร้านค้า", field: "shopName", width: 40 },
          { label: "ภาษี", field: "vatType", width: 15, dataFormat: format_vat_type },
          { label: "ยอดรวม", field: "totalItemPrice", width: 15 },
          { label: "ยอดที่คำนวนภาษี", field: "calculatedPrice", width: 15},
          { label: "ภาษีมูลค่าเพิ่ม", field: "vatAmount", width: 15},
          { label: "ยอดรวมทั้งหมด", field: "totalPrice", width: 15},
          { label: "เลขอ้างอิง", field: "groupName",  width: 30 },
          { label: "สร้างโดย", field: "createdByName",  width: 30 },

      ],

      excel_detail_invoice_list_header: [
        { label: "ออเดอร์", field: "orderID", width: 15},
        { label: "เลขแทรค", field: "trackingNo", width: 25 },
        { label: "วันที่สร้าง", field: "createdDatetime", width: 25 },
        { label: "วันที่แพ็ค", field: "packedDate", width: 25 },
        { label: "ขนส่ง", field: "expressCompanyName", width: 25 },
        { label: "น้ำหนัก", field: "boxWeight", width: 15 },
        { label: "กว้าง", field: "boxWeight", width: 15 },
        { label: "ยาว", field: "boxLong", width: 15 },
        { label: "สูง", field: "boxHigh", width: 15 },
        { label: "เลขอ้างอิง", field: "referenceNo", width: 30 },
        { label: "ค่าส่ง", field: "deliveryPrice", width: 15 },
        { label: "ค่าพื้นที่ห่างไกล", field: "remotePrice",  width: 15 },
        { label: "ค่า COD", field: "codPrice", width: 15 },
        { label: "ค่ากล่อง", field: 'boxPrice', width: 15 },
        { label: "ค่าแพค", field: "packPrice", width: 15 },
        { label:  "ค่าบับเบิล", field: "bubblePrice", width: 15 },
        { label: "ส่วนลด", field: "discount", width: 15},
        { label: "ค่าเชื่อมต่อ Vrich", field: "vrichFee", width: 15},
        { label: "ราคารวม", field: "totalPrice", width: 15},
      ],

      dialog_select_print: false,
      select_print_type: 1,
      item_for_print: null,

      data_group_detail: null,
      data_group_detailt_for_excel: [],

      // param check
      detail_invoiceGroupID : null,
      detail_totalItemPrice : null ,
      detail_calculatedPrice : null ,
      detail_vatType : null ,
      detail_vatAmount : null ,
      detail_totalPrice : null ,

    }),
    async created() {
      this.navigete = JSON.parse(localStorage.getItem('navigete'))
      await this.get_shop_all()
      this.search_shops = 0
      this.getInvoice()
      this.page_loading = false
    },
    computed: {
      computed_search_sent_date() {
        if(this.date_range[0] != null) {
          return this.formatDate(this.date_range)
        } else {
          return null
        }
      },
    },
    watch: {
      tabSelected: async function() {
        this.loading = true
         if(this.tabSelected==1){
          // this.invoice['datetime_search'] = [new Date(new Date().setDate(new Date().getDate()-1)).toISOString().substr(0, 10),new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10)]
          this.IsDataInvoice = 1
          this.getInvoice()
        }
      },
      startDate: async function(newVal, oldVal) {
        if (new Date(newVal)>=new Date(this.endDate)){
          this.checkStart = false
          this.startDate = oldVal
        }else if(this.checkStart){
          try {
            this.$refs.menuStartDate.save(this.startDate)
          }catch(err){
            console.log(err);
          }
        }else{
          this.checkStart = true
        }
      },
      endDate: async function(newVal, oldVal) {
        if (new Date(newVal)<=new Date(this.startDate) || new Date(newVal)>new Date()){
          this.checkEnd = false
          this.endDate = oldVal
        }else if(this.checkEnd){
          try {
            this.$refs.menuEndDate.save(this.endDate)
          }catch(err){
            console.log(err);
          }
        }else{
          this.checkEnd = true
        }
      },

      selectInvoiceDate: async function(newVal, oldVal) {
        if(oldVal != null){
          this.$refs.menuSelectInvoiceDate.save(this.selectInvoiceDate)
        }
      },


    },
    methods: {

      async get_shop_all () {
        let response = await axios.post(reportService_dotnet+'ShopInvoiceOrder/get-all-shop-in-branch-list', {
          "branchID": localStorage.getItem('Branch_BranchID'),
        },{ headers: header_token})

        this.shop['list'] = response.data
        this.shop['list'].unshift({'id': 0, 'name': '-- ทั้งหมด --'})
        this.shop['key_table']++
      },

      allowedDates: val => val <= new Date().toISOString().substr(0, 10),

      formatDate (date) {
        if (date.length == 0) return null

        if (date.length == 1) {
          const [year, month, day] = date[0].split('-')
          return `${day}-${month}-${year}`
        } else {
          const [year_from, month_from, day_from] = date[0].split('-')
          const [year_to, month_to, day_to] = date[1].split('-')
          if (date[0] < date[1]) {
            return `${day_from}-${month_from}-${year_from} ~ ${day_to}-${month_to}-${year_to}`
          } else {
            return `${day_to}-${month_to}-${year_to} ~ ${day_from}-${month_from}-${year_from}`
          }
        }
      },

      set_row_per_page () {
        this.row_per_page_select = parseInt(this.row_per_page_select_tmp)
      },

      async getInvoice() {
        // this.loading = true
        if (this.startDate != null && this.endDate != null) {
          this.date_range = [this.startDate, this.endDate]
        }else {
          this.date_range = []
        }

        if(this.search_InvoiceNo == ''){
          this.search_InvoiceNo = null
        }

        this.loading = true
        let res1 = await axios.post(reportService_dotnet+'Invoice/invoice-list', {
          "branchID": localStorage.getItem('Branch_BranchID'),
          "shopID": this.search_shops,
          "invoiceNo" : this.search_InvoiceNo ,
          "dateFrom": this.startDate,
          "dateTo": this.endDate,
          "skip": 0,
          "take": 1000000
        },
        { headers: header_token})
          const dateFrom_tmp = res1.data.dateFrom.split("-")
          const dateTo_tmp = res1.data.dateTo.split("-")
          this.startDate = dateFrom_tmp[2] + '-' + dateFrom_tmp[1] + '-' + dateFrom_tmp[0]
          this.endDate = dateTo_tmp[2] + '-' + dateTo_tmp[1] + '-' + dateTo_tmp[0]
          // this.selectInvoiceDate = dateTo_tmp[2] + '-' + dateTo_tmp[1] + '-' + dateTo_tmp[0]
          this.invoice['table'] = []
          this.invoice['table'] = res1.data.invoiceList

        this.invoice['showDatetime'] = false
        this.loading = false
      },

      calculateTotal(item) {
        var result = 0
        if(item.RemotePrice != '' && item.RemotePrice != null){
          result += parseFloat(item.RemotePrice)
        }
        if(item.CODCharged != '' && item.CODCharged != null){
          result += parseFloat(item.CODCharged)
        }
        if(item.PackPrice != '' && item.PackPrice != null){
          result += parseFloat(item.PackPrice)
        }
        if(item.BoxPrice != '' && item.BoxPrice != null){
          result += parseFloat(item.BoxPrice)
        }
        if(item.DeliveryPrice != '' && item.DeliveryPrice != null){
          result += parseFloat(item.DeliveryPrice)
        }
        if(item.Discount != '' && item.Discount != null){
          result += parseFloat(item.Discount)
        }

        if(item.ServiceVatAmount != '' && item.ServiceVatAmount != null){
          result += parseFloat(item.ServiceVatAmount)
        }

        if(item.CODVatAmount != '' && item.CODVatAmount != null){
          result += parseFloat(item.CODVatAmount)
        }
        return result.toFixed(2)
      },

      detail_order (item) {
        var str_len = item.orderID.length
        var order_id = item.orderID.substr(2, str_len)
        window.open('detail_order?id='+order_id, '_blank');
      },

      async openListOrderInvoice(row){
        window.open('/invoice-detail?invoiceNo='+row.invoiceNo);

      },

      dialog_print_open (item) {
        this.item_for_print = item
        var val = item.invoiceDate
        var date_tmp = val.substring(0, 10)
        // const date_split = date_tmp.split('-');
        // var time_tmp = val.substring(11, 19)
        // var set_dattime = date_split[2]+'-'+date_split[1]+'-'+date_split[0] +' '+ time_tmp
        this.selectInvoiceDate = date_tmp
        this.dialog_select_print = true
      },

      async PrintPreview(item){
        var invoiceGroupID_tmp = item.invoiceGroupID
        if (invoiceGroupID_tmp != null){
          invoiceGroupID_tmp = invoiceGroupID_tmp.toString()
        }

        const date_split = this.selectInvoiceDate.split('-');
        var iv_date = date_split[2] + '-' + date_split[1] + '-' + date_split[0]

        window.open('/print-invoice?inv=' + item.id.toString() + "&group=" + invoiceGroupID_tmp + '&type='+this.select_print_type.toString() + '&invoiceDate=' + iv_date + '&groupname=' + item.groupName );
        this.select_print_type = 1
        this.dialog_select_print = false
      },

      async check_delete (item) {
        if (this.get_premission_manage(22) == false) {
          this.permission_alert()
          return
        }

        let res1 = await axios.post(reportService_dotnet+'Invoice/get-invoice-befor-delete-invoice', {
          "invoiceID": item.id,
          "invoiceGroupID": item.invoiceGroupID
        },
        { headers: header_token})

        var invopice_title = ''
        for (var i = 0; i < res1.data.length; i++) {

          if(i == res1.data.length -1) {
            invopice_title += res1.data[i]
          } else {
            invopice_title += res1.data[i] + ', '
          }
        }

        Swal.fire({
          position: "top",
          icon: 'warning',
          title: 'ยกเลิกใบแจ้งหนี้',
          text: 'ใบแจ้งหนี้ ' + invopice_title + ' จะถูกลบออก !!',
          confirmButtonText: 'ยืนยัน',
          cancelButtonText: 'ยกเลิก',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          allowOutsideClick: false

        }).then((result) => {
          if (result.value) {
            this.confirm_delete(item)
          }
        })
      },

      async confirm_delete (item) {
        this.loading = true

        let res1 = await axios.post(reportService_dotnet+'Invoice/delete-invoice', {
          "invoiceID":item.id
        },
        { headers: header_token})
        this.loading = false

        this.getInvoice()
      },
      FormatInvoice,
      FormatOrder,
      formatMoney,
      isNumberWNoDot,
      set_format_date_time,
      format_price,
      format_vat_type,
      AlertSuccess,
      AlertWarning,
      AlertError,
      get_premission_manage,
      permission_alert
    }
  }
</script>

<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
      display: none;
  }

  .mobile-none {
    display: ;
  }

  .pc-none {
    display: none;
  }

  @media (max-width: 599px) {
    .mobile-none {
      display: none;
    }

    .pc-none {
      display: ;
    }
  }
</style>
